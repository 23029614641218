import React from 'react';
import Post from './post';
import * as styles from '../../assets/styles/postsBlock.module.sass';

function PostsBlock({ posts }) {
	return (
		<>
			<div className={styles.postsBlock}>
				{posts?.map((data, index) => (
					<Post key={index} data={data} />
				))}
			</div>
		</>
	);
}

export default PostsBlock;
