import React, { useState } from 'react';
import FilterNav from '../components/home/filterNav';
import Main from '../components/home/main';
import { graphql } from 'gatsby';

const IndexPage = ({
	data: {
		posts: { nodes: posts },
	},
}) => {
	const [postsArray, setPostsArray] = useState(posts);

	return (
		<>
			<FilterNav {...{ postsArray, initialPosts: posts, setPostsArray }} />
			<Main posts={postsArray} />
		</>
	);
};

export default IndexPage;

export const Head = ({ location: { pathname } }) => {
	const description =
		'Shodipo Ayomide works at Polygon as the Global Head for Developer Advocacy with 10 years of experience in Technology and a track record in Web & Mobile Engineering, Community Management, and cross team leardership on a global scale.';
	const title = 'Home | Shodipo Ayomide';

	return (
		<>
			<title>{title}</title>
			<meta name='description' content={description} />
			<meta property='og:locale' content='en_US' />
			<meta property='og:type' content='website' />
			<meta
				property='og:url'
				content={`https://shodipoayomide.com${pathname}`}
			/>
			<meta property='og:logo' content='/favicon.png' size='32x32' />

			{/* Twitter */}
			<meta name='twitter:title' content={title} />
			<meta name='twitter:description' content={description} />

			{/* Open Graph */}
			<meta property='og:title' content={title} key='ogtitle' />
			<meta property='og:description' content={description} key='ogdesc' />
			<script async src="https://www.googletagmanager.com/gtag/js?id=UA-125265218-1"></script>
			<script>
				{`
           window.dataLayer = window.dataLayer || [];
		   function gtag(){dataLayer.push(arguments);}
		   gtag('js', new Date());
		 
		   gtag('config', 'UA-125265218-1');
        `}
			</script>
		</>
	);
};

export const query = graphql`
	query HomePage {
		posts: allMarkdownRemark(
			sort: { order: DESC, fields: frontmatter___date }
		) {
			nodes {
				frontmatter {
					slug
					category
					title
					tagline
					date
					thumb {
						childImageSharp {
							gatsbyImageData(layout: FIXED)
						}
					}
				}
				id
			}
		}
		contact: site {
			siteMetadata {
				contact
			}
		}
	}
`;
