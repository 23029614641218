import React from "react";
import PostsBlock from "./postsBlock";
import * as styles from "../../assets/styles/main.module.sass";
import * as baseStyles from "../../assets/styles/base.module.sass";

function Main({ posts }) {
  return (
    <>
      <main>
        <div className={baseStyles.container}>
          <h1 className={styles.heading}>All</h1>
          <PostsBlock posts={posts} />
        </div>
      </main>
    </>
  );
}

export default Main;
