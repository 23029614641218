import React, { useState } from 'react';
import * as styles from '../../assets/styles/filterNav.module.sass';
import * as baseStyles from '../../assets/styles/base.module.sass';

function FilterNav({ postsArray, setPostsArray, initialPosts }) {
	const [searchValue, setSearchValue] = useState('');

	const handleSearch = (event) => {
		setSearchValue(event.target.value);
		if (event.target.value === '') {
			setPostsArray(initialPosts);
			return;
		}
		const searchRegex = new RegExp(event.target.value, 'gi');

		const matchedPosts = postsArray.filter(
			(post) =>
				searchRegex.test(post.frontmatter.title) ||
				searchRegex.test(post.frontmatter.tagline) ||
				searchRegex.test(post.frontmatter.category)
		);

		setPostsArray(matchedPosts);
	};

	return (
		<>
			<div className={styles.nav}>
				<div className={baseStyles.container}>
					<input
						type='text'
						value={searchValue}
						onChange={handleSearch}
						placeholder='Search Posts'
					/>
				</div>
			</div>
		</>
	);
}

export default FilterNav;
