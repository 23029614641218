import { Link } from 'gatsby';
import React from 'react';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import * as styles from '../../assets/styles/post.module.sass';

function Post({ data }) {
	const result = data.frontmatter;
	const thumb = getImage(result?.thumb);

	return (
		<>
			<Link to={result?.slug || '/404'} className={styles.post}>
				<figure>
					<div className={styles.postImage}>
						<Img image={thumb} alt={result?.title + ' cover photo'} />
					</div>
					<figcaption>
						<div className={styles.badges}>
							{result?.category?.split(/,[\s*]?/g).map((badge, index) => (
								<span key={index} className={styles.categoryBadge}>
									{badge}
								</span>
							))}
						</div>

						<h2 className={styles.postTitle}>{result?.title}</h2>
						<div className={styles.postTagLine}>{result?.tagline}</div>
						<div className={styles.postDate}>{result?.date}</div>
					</figcaption>
				</figure>
			</Link>
		</>
	);
}

export default Post;
